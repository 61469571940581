/* eslint-disable react/jsx-pascal-case */
import { graphql } from 'gatsby';
import { Box } from 'grommet';
import sortBy from 'lodash.sortby';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './products.styl';

import {
    PAGE_CALENDAR,
    PAGE_CARTERIE,
    PAGE_OBJET_CADEAU,
    PAGE_PAPETERIE,
} from '../constants/router';
import Container from '../views/components/Container';
import FormattedMessageWithClassName from '../views/components/FormattedMessageWithCN';
import Gallery from '../views/components/Gallery';
import LocalizedLink from '../views/components/LocalizedLink';
import SEO from '../views/components/SEO';
import ToolBar from '../views/components/ToolBar';
import MainLayout from '../views/layouts/MainLayout';

const ProductsPage = ({
    data,
    location,
    pageContext: { breadcrumb, locale, translations },
}) => {
    const filters = [
        {
            label: 'categories',
            link: true,
            list: data.allProducts.group,
        },
    ];
    const list = data.allProducts.edges.map((n) => n.node);
    const sortedList = sortBy(list, ['fields.section', 'titre']);
    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            filters={filters}
            neverTransparent
        >
            <SEO
                title='product.headerTitle'
                location={location}
                translationsPaths={translations}
                description='product.description'
            />
            <div className='header-spacer' />
            <main id='content' className='gap'>
                <Container>
                    <FormattedMessageWithClassName
                        id='product.title'
                        tagName='h1'
                        className='hero-title'
                    />
                    <Box
                        className='products-section-links'
                        direction='row'
                        justify='center'
                        // gap='large'
                        responsive
                        wrap
                    >
                        <LocalizedLink to={PAGE_CARTERIE} hasSlug>
                            <FormattedMessage id='product.sections.cards' />
                        </LocalizedLink>
                        <LocalizedLink to={PAGE_PAPETERIE} hasSlug>
                            <FormattedMessage id='product.sections.stationery' />
                        </LocalizedLink>
                        <LocalizedLink to={PAGE_OBJET_CADEAU} hasSlug>
                            <FormattedMessage id='product.sections.items' />
                        </LocalizedLink>
                        <LocalizedLink to={PAGE_CALENDAR} hasSlug>
                            <FormattedMessage id='product.sections.calendars' />
                        </LocalizedLink>
                    </Box>
                </Container>
                <ToolBar crumbs={breadcrumb.crumbs} hasFilter />
                <Container>
                    <Gallery cards={sortedList} />
                </Container>
            </main>
        </MainLayout>
    );
};

ProductsPage.propTypes = {
    data: PropTypes.shape({
        allProducts: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        breadcrumb: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default ProductsPage;

export const query = graphql`
    query ($locale: String!) {
        allProducts(
            filter: {
                lang: { eq: $locale }
                fields: { section: { ne: "partnership" } }
            }
        ) {
            edges {
                node {
                    ...ProductCard
                }
            }
            group(field: fields___section) {
                totalCount
                fieldValue
            }
        }
    }
`;
